import React from 'react'

const HistoryGuestlacture = () => {
  return (
    <div className="col-lg-10 ps-0">
      <div className='container-fluid px-5'>
        History Guest lacture
        </div>
    </div>
  )
}

export default HistoryGuestlacture
