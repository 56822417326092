import React from 'react'

const RegisterGuestLacture = () => {
  return (
    <div className="col-lg-10 ps-0">
        Register Guest Lacture
      
    </div>
  )
}

export default RegisterGuestLacture
