export const option_fro_timezone = [
  "UTC-12:00: Baker Island Time (BIT)",
  "UTC-11:00: Niue Time (NUT), Samoa Standard Time (ST)",
  "UTC-10:00: Hawaii-Aleutian Standard Time (HAST), Tahiti Time (TAHT)",
  "UTC-09:00: Alaska Standard Time (AKST)",
  "UTC-08:00: Pacific Standard Time (PST)",
  "UTC-07:00: Mountain Standard Time (MST)",
  "UTC-06:00: Central Standard Time (CST)",
  "UTC-05:00: Eastern Standard Time (EST)",
  "UTC-04:00: Atlantic Standard Time (AST), Eastern Caribbean Time (ECT)",
  "UTC-03:00: Argentina Time (ART), Brasília Time (BRT)",
  "UTC-02:00: South Georgia Time (GST)",
  " UTC-01:00: Cape Verde Time (CVT)",
  "UTC±00:00: Coordinated Universal Time (UTC), Greenwich Mean Time (GMT)",
  "UTC+01:00: Central European Time (CET), West Africa Time (WAT)",
  "UTC+03:00: Moscow Time (MSK), East Africa Time (EAT)",
  "UTC+04:00: Azerbaijan Time (AZT), Gulf Standard Time (GST)",
  "UTC+05:00: Pakistan Standard Time (PKT), Yekaterinburg Time (YEKT)",
  "UTC+05:30: Indian Standard Time (IST), Sri Lanka Time (SLT)",
  " UTC+05:45: Nepal Time (NPT)  ",
  " UTC+06:00: Bangladesh Standard Time (BST), Omsk Time (OMST)",
  " UTC+06:30: Cocos Islands Time (CCT)",
  " UTC+07:00: Indochina Time (ICT), Krasnoyarsk Time (KRAT)",
  "UTC+08:00: China Standard Time (CST), Australian Western Standard Time (AWST)",
  "UTC+09:00: Japan Standard Time (JST), Korea Standard Time (KST)",
  "UTC+09:30: Australian Central Standard Time (ACST)",
  " UTC+10:00: Australian Eastern Standard Time (AEST), Papua New Guinea Time (PGT)",
  "UTC+10:30: Lord Howe Standard Time (LHST)",
  "   UTC+11:00: Solomon Islands Time (SBT), Vanuatu Time (VUT)",
  " UTC+12:00: Fiji Time (FJT), New Zealand Standard Time (NZST)",
  " UTC+13:00: Tonga Time (TOT), Phoenix Island Time (PHOT)",
  " UTC+14:00: Line Islands Time (LINT)",
  "UTC+03:30: Iran Standard Time (IRST)",
  "UTC+04:30: Afghanistan Time (AFT)",

  "UTC+06:30: Cocos Islands Time (CCT)",
  "UTC+03:45: Nepal Time (NPT)",
  "UTC+05:45: Nepal Time (NPT)",
  "UTC+09:45: Australian Central Standard Time (ACST)",
  "UTC+12:45: Chatham Islands Time (CHAST)",
];
