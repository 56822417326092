import React from "react";
import CaseStudyDisplay from "../../../CaseStudy/CaseStudy.js";

const InstituteUserList = () => {
  return (
    <>
      <CaseStudyDisplay />
    </>
  );
};

export default InstituteUserList;
