export const options = [
  { value: "accounting", label: "Accounting" },
  { value: "advertising", label: "Advertising" },
  { value: "agriculture", label: "Agriculture" },
  { value: "analytics", label: "Analytics" },
  { value: "architecture", label: "Architecture" },
  { value: "automotive", label: "Automotive" },
  { value: "aviation", label: "Aviation" },
  { value: "bd_sales", label: "BD/Sales" },
  { value: "bfsi", label: "BFSI" },
  { value: "biotechnology", label: "Biotechnology" },
  { value: "consulting", label: "Consulting" },
  { value: "cybersecurity", label: "Cybersecurity" },
  { value: "defence", label: "Defence" },
  { value: "digital_marketing", label: "Digital Marketing" },
  { value: "e_commerce", label: "E-Commerce" },
  { value: "education", label: "Education" },
  { value: "energy_oil_gas", label: "Energy/Oil & Gas" },
  { value: "engineering", label: "Engineering" },
  { value: "entrepreneurship", label: "Entrepreneurship" },
  { value: "equity_research", label: "Equity Research" },
  { value: "event_management", label: "Event Management" },
  { value: "fashion", label: "Fashion" },
  { value: "finance", label: "Finance" },
  { value: "fmcg", label: "FMCG" },
  { value: "food_beverage", label: "Food & Beverage" },
  { value: "general_management", label: "General Management" },
  { value: "government", label: "Government" },
  { value: "healthcare", label: "Healthcare" },
  { value: "hi_tech", label: "Hi-Tech" },
  { value: "hospitality", label: "Hospitality" },
  { value: "hr", label: "HR" },
  { value: "industrial_design", label: "Industrial Design" },
  { value: "infra_power", label: "Infra/Power" },
  { value: "innovation", label: "Innovation" },
  { value: "insurance", label: "Insurance" },
  { value: "investment_banking", label: "Investment Banking" },
  { value: "iot", label: "IoT" },
  { value: "it", label: "IT" },
  { value: "law", label: "Law" },
  { value: "logistics", label: "Logistics" },
  { value: "manufacturing", label: "Manufacturing" },
  { value: "marketing", label: "Marketing" },
  { value: "media", label: "Media" },
  { value: "metal", label: "Metal" },
  { value: "mining", label: "Mining" },
  { value: "non_profit", label: "Non-Profit" },
  { value: "operation_supply_chain", label: "Operation/Supply Chain" },
  { value: "pharma_healthcare", label: "Pharma/Healthcare" },
  { value: "product_management", label: "Product Management" },
  { value: "project_management", label: "Project Management" },
  { value: "public_policy", label: "Public Policy" },
  { value: "pvt_equity", label: "Pvt Equity" },
  { value: "real_estate", label: "Real Estate" },
  { value: "retail", label: "Retail" },
  { value: "robotics", label: "Robotics" },
  { value: "sales", label: "Sales" },
  { value: "software_development", label: "Software Development" },
  { value: "sports", label: "Sports" },
  { value: "sustainability", label: "Sustainability" },
  { value: "telecommunications", label: "Telecommunications" },
  { value: "tourism", label: "Tourism" },
  { value: "transport", label: "Transport" },
  { value: "venture_capital", label: "Venture Capital" },
  { value: "virtual_reality", label: "Virtual Reality" },
  { value: "waste_management", label: "Waste Management" },
];

export const experienceOptions = [
  { value: "5-8", label: "5-8 years" },
  { value: "8-11", label: "8-11 years" },
  { value: "11-14", label: "11-14 years" },
  { value: "14-17", label: "14-17 years" },
  { value: "17-20", label: "17-20 years" },
  { value: "20-23", label: "20-23 years" },
  { value: "23-26", label: "23-26 years" },
  { value: "26-29", label: "26-29 years" },
  { value: "30+", label: "30+ years" },
];
